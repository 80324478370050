<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area pt--120 pb--190 bg_image dcrawler-bg"
      data-black-overlay="6"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center rn-page-title pt--100">
              <h2 class="heading-title" style="color:#fff;">DCrawler</h2>
              <p>
                
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <div class="rn-service-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="service-details-inner">
              <div class="inner">
                <!-- Start Single Content  -->
                <v-row class="sercice-details-content align-center row--35 ">
                  <v-col lg="12" md="12" cols="12">
                    <div class="details mt_md--30 mt_sm--30">
                      <div class="section-title">
                        <span class="subtitle">Our service</span>
                        <h2 class="heading-title">Why DCrawler?</h2>
                        <p calss="description" align="justify">
                        DCrawler를 통해 데이터 수집의 새로운 지평을 열어보세요. 복잡한 데이터 환경에서의 효율적인 정보 수집과 관리는 비즈니스 성공의 핵심입니다. DCrawler는 선진 웹 크롤링 기술을 활용해 대규모 데이터를 신속하고 정확하게 수집, 당신의 데이터 분석 및 인사이트 발견을 강화합니다. 이를 통해 사용자는 깊이 있는 분석과 통찰을 얻고, 데이터에 기반한 의사결정을 더욱 견고히 할 수 있습니다.
                        </p>
                        <p calss="description" align="justify">
                        정교한 필터링 시스템을 갖춘 DCrawler는 데이터 엔지니어링 작업을 용이하게 하며, 복잡한 데이터 환경을 효과적으로 탐색하도록 지원합니다. 데이터의 잠재력을 극대화하고자 한다면, DCrawler는 이상적인 선택입니다. 강력하면서도 실용적인 데이터 크롤링 솔루션, DCrawler로 여러분의 업무를 한 단계 업그레이드하세요.
                        </p>
                        <!-- <p class="description">
                        - 수집대상별로 정의된 Action순서에 따라 WebDriver를 제어하여, 수집을 원하는 웹페이지로 접근, 필요한 동작을 수행함<br />
                        - WebDriver를 통해 사용자가 웹브라우저를 통하여 보는 것과 똑같은 HTML문서에서 데이터를 추출<br />
                        - TXT, CSV, Excel, 검색엔진, RDB 등 다양한 형태의 결과물을 지원
                        </p> -->
                        <p calss="description"></p>
                        <!-- <dl>
                          <dt style="font-weight: bold;">동적 웹페이지 인터랙션을 위한 정교한 WebDriver 제어</dt>
                          <dd style="text-align: justify;">DCrawler는 각 수집 대상에 맞게 세밀하게 정의된 Action 순서에 따라 WebDriver를 조작합니다. 이를 통해 원하는 웹페이지에 정확히 접근하여 필요한 동작(예: 페이지 네비게이션, 폼 제출, AJAX 요청 처리 등)을 수행할 수 있습니다. 이는 특히 JavaScript가 활발히 사용되는 동적 웹 페이지의 데이터 수집에 효과적입니다.</dd>

                          <dt style="font-weight: bold;">사용자 경험과 동일한 환경에서의 데이터 추출</dt>
                          <dd style="text-align: justify;">DCrawler는 WebDriver를 활용하여 사용자가 웹 브라우저에서 보는 것과 동일한 HTML 문서로부터 데이터를 추출합니다. 이는 웹 페이지의 실제 렌더링 결과를 기반으로 한 데이터 수집을 가능하게 하며, 클라이언트 사이드 스크립트에 의해 동적으로 생성되거나 변경되는 콘텐츠를 포함한 완전한 페이지 데이터를 확보할 수 있습니다.</dd>

                          <dt style="font-weight: bold;">다양한 출력 포맷 지원</dt>
                          <dd style="text-align: justify;">DCrawler는 수집된 데이터를 다양한 형태로 출력할 수 있는 기능을 제공합니다. 이는 텍스트 파일(TXT), CSV, 엑셀(Excel) 형식 뿐만 아니라 검색엔진 최적화, 관계형 데이터베이스(RDB) 등 여러 형식으로의 데이터 변환 및 통합을 지원하여, 데이터 분석 및 보고서 작성, 시스템 통합 등 다양한 후속 작업에 유연하게 대응할 수 있습니다.</dd>
                        </dl> -->
                      </div>
                    </div>
                  </v-col>

                  <v-col lg="12" md="12" cols="12 mt--30 mb--120">
                    <div class="details mt_md--30">
                      <div class="section-title">
                        <span class="subtitle">동적 웹페이지 인터랙션을 위한 정교한 WebDriver 제어</span>
                        <p class="description" align="justify">DCrawler는 각 수집 대상에 맞게 세밀하게 정의된 Action 순서에 따라 WebDriver를 조작합니다. 이를 통해 원하는 웹페이지에 정확히 접근하여 필요한 동작(예: 페이지 네비게이션, 폼 제출, AJAX 요청 처리 등)을 수행할 수 있습니다. 이는 특히 JavaScript가 활발히 사용되는 동적 웹 페이지의 데이터 수집에 효과적입니다.
                        </p>
                        <br/><br/>
                        <span class="subtitle">사용자 경험과 동일한 환경에서의 데이터 추출</span>
                        <p class="description" align="justify">DCrawler는 WebDriver를 활용하여 사용자가 웹 브라우저에서 보는 것과 동일한 HTML 문서로부터 데이터를 추출합니다. 이는 웹 페이지의 실제 렌더링 결과를 기반으로 한 데이터 수집을 가능하게 하며, 클라이언트 사이드 스크립트에 의해 동적으로 생성되거나 변경되는 콘텐츠를 포함한 완전한 페이지 데이터를 확보할 수 있습니다.
                        </p>
                        <br/><br/>
                        <span class="subtitle">다양한 출력 포맷 지원</span>
                        <p class="description" align="justify">DCrawler는 수집된 데이터를 다양한 형태로 출력할 수 있는 기능을 제공합니다. 이는 텍스트 파일(TXT), CSV, 엑셀(Excel) 형식 뿐만 아니라 검색엔진 최적화, 관계형 데이터베이스(RDB) 등 여러 형식으로의 데이터 변환 및 통합을 지원하여, 데이터 분석 및 보고서 작성, 시스템 통합 등 다양한 후속 작업에 유연하게 대응할 수 있습니다.
                        </p>
                        <br/><br/>
                      </div>
                    </div>
                  </v-col>


                  <v-col lg="12" md="12" cols="12 mt--30 mb--120">
                    <div class="thumb position-relative">
                      <div class="thumbnail position-relative">
                        <img class="w-100" src="../../assets/images/product/dcrawler01.png" alt="" />
                      </div>
                    </div>
                  </v-col>
                  
                  <v-col lg="12" md="12" cols="12">
                  </v-col>
                </v-row>
                <!-- End Single Content  -->
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="rn-service-details ptb--120 bg_color--5" style="display:none;">
      <v-container>
        <!-- Start Single Content  -->
        <v-row class="sercice-details-content align-center row--35">
          <v-col lg="6" md="6" cols="12" order="2" order-md="1">
            <div class="details mt_md--30 mt_sm--30">
              <div class="section-title">
                <h2 class="heading-title">메인추천</h2>
                <p class="description">
                  사용자의 관심데이터와 트렌드를 바탕으로 메인화면을 통해 다양한 기업을 추천해 줍니다.
                </p>
              </div>
            </div>
          </v-col>
          <v-col lg="6" md="2" cols="12" order="1" order-md="2">
            <div class="thumb position-relative">
              <img
                class="w-100" src="../../assets/images/service/service-02.png"
                alt="Service Images"
              />
            </div>
          </v-col>
          
        </v-row>
        <!-- End Single Content  -->
      </v-container>
    </div>
    
    <Footer />
  </div>
</template>

<script>
  import feather from "feather-icons";
  import Header from "../../components/header/Header";
  import Footer from "../../components/footer/Footer";
  import PricingPlan from "../../components/pricing-plan/PricingPlan";
  export default {
    components: {
      Header,
      Footer,
      PricingPlan,
    },
    data() {
      return {
        logo: require("../../assets/images/logo/logo.png"),
        items: [
          {
            thumb: require("../../assets/images/product/dstocks_site.png"),
            src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
          },
        ],
        index: null,
        listOne: [
          {
            id: 1,
            icon: "check",
            desc: `5 PPC Campaigns the majority.`,
          },
          {
            id: 2,
            icon: "check",
            desc: `Digital Marketing going to.`,
          },
          {
            id: 3,
            icon: "check",
            desc: ` Marketing Agency passage of.`,
          },
          {
            id: 4,
            icon: "check",
            desc: ` Seo Friendly you are going.`,
          },
          {
            id: 5,
            icon: "check",
            desc: `5 PPC Campaigns the majority.`,
          },
          {
            id: 6,
            icon: "check",
            desc: `Fast-Track Your business`,
          },
        ],
      };
    },

    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
